import React from "react"
import { graphql } from "gatsby"
import { css } from "@emotion/core"
import Layout from "../components/layout"
import SEO from '../components/seo'
import Masonry from 'react-masonry-css'
import { Post } from '../pages'

export const query = graphql`
  query CategoryQuery($id: String) {
    prismic {
      allPosts(where: { category: $id } sortBy: meta_lastPublicationDate_DESC) {
        edges {
          node {
            ...PostData
          }
        }
      }
    }
  }
`

export default ({ data, pathContext }) => {

  const posts = data.prismic.allPosts.edges
  return (
    <>
    <SEO title={`Category: ${pathContext.name}`} />
    <Layout>
      <Masonry breakpointCols={{ default: 2, 768: 1 }} css={css` 
      
  @media (max-width: 768px) {
    padding: 20px;
  }      
  @media (min-width: 768px) {
    
    display: flex;
    margin-left: -95px; /* gutter size offset */
    width: auto;
    padding: 95px;
    
    .column{
      padding-left: 95px;
      background-clip: padding-box;
    }

  }
  `}  columnClassName="column">
        {/* <BlogPosts posts={posts} /> */}
        {posts.map((post, index) => <Post key={index} post={post} />)}
      </Masonry>
    </Layout>
    </>
  )

}